import {
  Autocomplete,
  FormControl,
  FormLabel,
  Paper,
  TextField,
} from "@mui/material";
import PropTypes from "prop-types";
import React, {
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useState,
} from "react";
import { getLocationErrorMessage } from "../../constant/messages";
import { debounce } from "../../constant/utils";
import { getLocations } from "../../services/form.service";
import { FormStyles, styles } from "./styleSheet";

const CustomPaperComponent = ({ children, ...otherProps }) => {
  return (
    <Paper {...otherProps} style={FormStyles.autocompletePaper}>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          style: {
            ...child.props.style,
            ...FormStyles.autocompletePaper["& > *"],
          },
        })
      )}
    </Paper>
  );
};

CustomPaperComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

const LocationAutocomplete = React.memo(
  ({
    value,
    onChange,
    onBlur,
    placeholder,
    error,
    helperText,
    inputProps,
    formLabel,
    locationError,
    isServices,
    isOnlyUser,
    isHomeLocation,
  }) => {
    const [inputValue, setInputValue] = useState("");
    const [options, setOptions] = useState([]);
    const [isOptionSelected, setIsOptionSelected] = useState(false);
    const [readOnly, setReadOnly] = useState(false);

    const deferredInputValue = useDeferredValue(inputValue);

    const fetchSuggestions = async (query) => {
      try {
        const response = await getLocations(query);
        const suggestions = response?.data?.result?.predictions || [];

        // Sort suggestions by length (shorter suggestions first)
        const sortedSuggestions = suggestions.sort(
          (a, b) => a.description.length - b.description.length
        );

        setOptions(sortedSuggestions);
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      }
    };

    const debouncedFetchSuggestions = useMemo(
      () =>
        debounce((query) => {
          if (!isOptionSelected) {
            fetchSuggestions(query);
          }
        }, 400),
      [isOptionSelected]
    );

    const handleOptionChange = useCallback(
      (event, newValue) => {
        onChange(event, newValue);
        setInputValue(newValue?.description || "");
        setIsOptionSelected(true);
        setReadOnly(true);
        setOptions([]);
      },
      [onChange]
    );

    const handleInputChange = (event, newInputValue) => {
      if (isOptionSelected) {
        setIsOptionSelected(false);
        return;
      }

      const matchingOption = options.find(
        (option) => option.description === newInputValue
      );

      if (matchingOption) {
        return;
      }

      setInputValue(newInputValue);
      if (newInputValue.length > 1) {
        debouncedFetchSuggestions(newInputValue);
      } else {
        setOptions([]);
      }

      if (readOnly) {
        setReadOnly(false);
      }
    };

    useEffect(() => {
      if (!deferredInputValue) {
        setInputValue("");
        setReadOnly(false);
      }
    }, [deferredInputValue]);

    useEffect(() => {
      if (!readOnly && inputValue.length === 0) {
        setOptions([]);
      }
    }, [readOnly, inputValue]);

    return (
      <div style={styles.formControlWrapper}>
        <FormControl fullWidth margin="normal" sx={styles.inputContainer}>
          <FormLabel style={styles.formLabel}>{formLabel}</FormLabel>
          <Autocomplete
            sx={styles.googleAutocompleteBoxStyle(locationError)}
            options={options}
            value={value}
            onChange={handleOptionChange}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            isOptionEqualToValue={(option, value) =>
              option?.place_id === value?.place_id
            }
            onBlur={onBlur}
            freeSolo
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option?.description || ""
            }
            noOptionsText={inputValue ? "No matching results" : ""}
            filterOptions={(x) => x}
            PaperComponent={(props) => <CustomPaperComponent {...props} />}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={placeholder}
                fullWidth
                error={error}
                helperText={helperText}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  ...inputProps,
                  style: FormStyles.inputField,
                  endAdornment: <>{params.InputProps.endAdornment}</>,
                  sx: styles.googlePlacesInput,
                }}
                inputProps={{ ...params.inputProps, readOnly }}
              />
            )}
          />
        </FormControl>
        {locationError && !error && (
          <div className="location-errors">
            {getLocationErrorMessage(isServices, isOnlyUser, isHomeLocation)}
          </div>
        )}
      </div>
    );
  }
);

LocationAutocomplete.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  inputProps: PropTypes.object,
  formLabel: PropTypes.string,
  locationError: PropTypes.bool,
  isServices: PropTypes.bool,
  isOnlyUser: PropTypes.bool,
  isHomeLocation: PropTypes.bool,
};

export default LocationAutocomplete;
