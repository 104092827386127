import Lottie from "lottie-react";
import React from "react";
import HeroSectionAugAnimationData from "../assets/Animations/hero-section-animation-15-aug.json";
import "./LottieAnimation.css";
const HeroSectionAnimation = () => {
  return (
    <div className="hero-animation-container">
      <Lottie
        animationData={HeroSectionAugAnimationData}
        loop
        autoplay
        style={{ width: "100%", height: "100%" }}
      />
    </div>
  );
};

export default HeroSectionAnimation;
