import React from "react";
import Layout from "../../Layout";
import AllServices from "../../components/AllServices/AllServices";
import PopularServices from "../../components/AllServices/PopularServices";
import Seo from "../../components/SEO/Seo";
import { servicesListSEO } from "../../constant/staticData";
function Services() {
  return (
    <Layout>
      <Seo
        title="Bataiyo | Trusted Services"
        description="Discover and connect with trusted providers offering services like Daily Needs, Domestic Services, Retail & Shopping, Travel, and more on Bataiyo, a zero-commission platform."
        keywords={servicesListSEO.join(", ")}
        url="services"
      />
      <PopularServices />
      <AllServices />
    </Layout>
  );
}

export default Services;
