import Blog from "../pages/Blog/Blog";
import ViewBlogPost from "../pages/Blog/ViewBlogPost";
import CookiesPolicy from "../pages/CookiesPolicy/CookiesPolicy";
import DeleteAccount from "../pages/DeleteAccount/DeleteAccount";
import DeviceRedirectHandler from "../pages/DeviceRedirectHandler/DeviceRedirectHandler";
import Disclaimer from "../pages/Disclaimer/Disclaimer";
import LandingPage from "../pages/LandingPage/LandingPage";
import PageNotFound from "../pages/PageNotFound/PageNotFound";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import Registration from "../pages/Registration/Registration";
import Services from "../pages/ServicesPage/Services";
import TermsAndConditions from "../pages/TermsAndConditions/TermsAndConditions";
const routes = [
  { id: 1, path: "/", exact: true, element: <LandingPage /> },
  { id: 2, path: "/privacy", exact: true, element: <PrivacyPolicy /> },
  { id: 3, path: "/tnc", exact: true, element: <TermsAndConditions /> },
  { id: 4, path: "/delete", exact: true, element: <DeleteAccount /> },
  { id: 5, path: "/cookies-policy", exact: true, element: <CookiesPolicy /> },
  { id: 6, path: "/disclaimer", exact: true, element: <Disclaimer /> },
  { id: 7, path: "/blog", exact: true, element: <Blog /> },
  { id: 8, path: "/blog/:id", exact: true, element: <ViewBlogPost /> },
  { id: 9, path: "/register", exact: true, element: <Registration /> },
  { id: 10, path: "/services", exact: true, element: <Services /> },
  { id: 11, path: "*", exact: true, element: <PageNotFound /> },
  {
    id: 12,
    path: "/app",
    exact: true,
    element: <DeviceRedirectHandler />,
  },
];

export default routes;
