const servicesMainContainer = (isMediumScreen) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: isMediumScreen ? "32px" : "64px",
  padding: isMediumScreen ? "16px 28px" : "64px 96px",
});
const popularServicesContainer = {
  display: "flex",
  gap: "24px",
  flexDirection: "column",
  justifyContent: "start",
  textAlign: "start",
};
const servicesHeading = (isSmallScreen) => ({
  fontSize: isSmallScreen ? "32px" : "48px",
  lineHeight: isSmallScreen ? "48px" : "60px",
  fontWeight: "700",
  color: "#14171F",
  margin: "0",
});
const popularServicesHeadingBox = (isSmallScreen) => ({
  display: "flex",
  flexDirection: isSmallScreen ? "column-reverse" : "row",
  gap: "20px",
  alignItems: "start",
  justifyContent: "start",
});
const popularServicesText = (isSmallScreen) => ({
  fontSize: isSmallScreen ? "24px" : "40px",
  lineHeight: isSmallScreen ? "30px" : "50px",
  fontWeight: "600",
  color: "#14171F",
  margin: "0",
  textAlign: "start",
});
const horizonatalContainer = {
  display: "flex",
  overflowX: "auto",
  gap: "10px",
  padding: "10px",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  scrollbarWidth: "none",
};

const popularServicesCard = (isSmallScreen) => ({
  maxWidth: "100%",
  borderRadius: "24px",
  display: "flex",
  gap: isSmallScreen ? "8px" : "16px",
  flexDirection: "column",
  width: "100%",
});
const popularServicesImg = (isSmallScreen) => ({
  borderRadius: "16px",
  width: isSmallScreen ? "180px" : "288px",
  height: isSmallScreen ? "117px" : "200px",
  objectFit: "contain",
  alignSelf: "center",
  backgroundColor: "#BBDFE4",
});
const serviceCardItem = (isSmallScreen) => ({
  minWidth: isSmallScreen ? "180px" : "288px",
  marginRight: "16px",
});
const cardContentBox = (isSmallScreen) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "start",
  gap: isSmallScreen ? "4px" : "8px",
  textAlign: "start",
  height: "80px",
  "&:last-child": {
    padding: isSmallScreen ? "0 8px 4px 8px" : "0 12px 8px 12px",
  },
});

const serviceName = (isSmallScreen) => ({
  fontSize: isSmallScreen ? "14px" : "18px",
  lineHeight: isSmallScreen ? "18px" : "23px",
  fontWeight: "500",
  color: "#252525",
  overflowWrap: "anywhere",
});
const serviceDesc = (isSmallScreen) => ({
  fontSize: isSmallScreen ? "12px" : "16px",
  lineHeight: isSmallScreen ? "15px" : "20px",
  fontWeight: "400",
  color: "#505050",
});
const allServicesContainer = (isMediumScreen) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: isMediumScreen ? "8px" : "18px",
  padding: isMediumScreen ? "16px 28px" : "0 96px 64px 96px",
});
const categoriesText = (isSmallScreen) => ({
  fontSize: isSmallScreen ? "18px" : "36px",
  lineHeight: isSmallScreen ? "22px" : "42px",
  fontWeight: "600",
  color: "#14171F",
  margin: "0",
});
const categoriesNameText = (isSmallScreen) => ({
  textAlign: "start",
  fontSize: isSmallScreen ? "22px" : "32px",
  fontWeight: "600",
  lineHeight: isSmallScreen ? "22px" : "40px",
  color: "#000000",
  marginBottom: "18px",
});
const horizontalLine = {
  height: "1px",
  backgroundColor: "#DFDFE1",
  width: "100%",
  marginTop: "12px",
};
const subCategoriesText = (isSmallScreen) => ({
  margin: "0",
  fontSize: isSmallScreen ? "18px" : "22px",
  fontWeight: "600",
  color: "#000000",
  textAlign: "start",
});
const servicesCardPaper = (isSmallScreen) => ({
  padding: isSmallScreen ? "8px 12px" : "12px 16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: "16px",
  border: "1px solid #DFDFE1",
  boxShadow: "none !important",
});
const servicesIcon = (isSmallScreen) => ({
  width: isSmallScreen ? "36px" : "42px",
  height: isSmallScreen ? "36px" : "42px",
});
const serviceNameText = (isSmallScreen) => ({
  fontSize: isSmallScreen ? "14px" : "18px",
  lineHeight: "22px",
  color: "#000000",
  fontWeight: "400",
  textAlign: "start",
  overflowWrap: "anywhere",
});
const arrowIcon = (isSmallScreen) => ({
  backgroundColor: "#EBF4FF",
  borderRadius: "50%",
  padding: isSmallScreen ? "4px" : "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: isSmallScreen ? "8px" : "12px",
});
const notAvailableBox = {
  padding: "12px 16px",
  alignItems: "center",
  borderRadius: "16px",
  border: "1px solid #DFDFE1",
};
const serviceNameBox = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "16px",
};
const categoriesContainer = {
  display: "flex",
  flexWrap: "wrap",
  gap: "10px",
  marginTop: "10px",
  alignItems: "center",
  justifyContent: "start",
};
const categoryBox = (selectedCategoryId, isSmallScreen) => ({
  borderRadius: "20px",
  border: !selectedCategoryId && "1px solid #000000",
  padding: "4px 12px",
  cursor: "pointer",
  fontSize: isSmallScreen ? "12px" : "16px",
  color: selectedCategoryId ? "#d9d9d9" : "#000000",
  backgroundColor: selectedCategoryId ? "#0072dd" : "transparent",
  WebkitTapHighlightColor: "transparent",
});
const allServicesTextBox = (isSmallScreen, isMediumScreen) => ({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: isMediumScreen ? "column" : "row",
  gap: isSmallScreen ? "12px" : "28px",
});
const categoriesMainContainer = (isMediumScreen) => ({
  width: isMediumScreen ? "100%" : "50%",
});
const categoriesTextStyle = (isSmallScreen) => ({
  textAlign: "start",
  marginBottom: "8px",
  fontSize: isSmallScreen ? "14px" : "22px",
  lineHeight: isSmallScreen ? "18px" : "28px",
  fontWeight: "600",
  color: "#14171F",
});
const categoryImgBox = {
  display: "flex",
  alignItems: "center",
  gap: "14px",
};
const subcategoryImgBox = {
  display: "flex",
  alignItems: "center",
  gap: "8px",
  marginLeft: "4px",
};
const categoryIcon = { width: "40px", height: "40px" };
const subcategoryIcon = { width: "28px", height: "28px" };
const displayCategoriesCont = { marginTop: "24px" };
export const styles = {
  servicesMainContainer,
  popularServicesContainer,
  popularServicesHeadingBox,
  servicesHeading,
  horizonatalContainer,
  popularServicesText,
  popularServicesCard,
  popularServicesImg,
  cardContentBox,
  serviceName,
  serviceDesc,
  serviceCardItem,
  allServicesContainer,
  categoriesText,
  categoriesNameText,
  horizontalLine,
  subCategoriesText,
  servicesCardPaper,
  servicesIcon,
  serviceNameText,
  arrowIcon,
  notAvailableBox,
  serviceNameBox,
  categoriesContainer,
  categoryBox,
  allServicesTextBox,
  categoriesMainContainer,
  categoriesTextStyle,
  categoryImgBox,
  subcategoryImgBox,
  categoryIcon,
  subcategoryIcon,
  displayCategoriesCont,
};
