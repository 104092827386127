import { Button, useMediaQuery } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React, { memo, useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Link } from "react-router-dom";
import { isDisableForSubmit } from "../../constant/formValidationSchema";
import { BataiyoLoader, ErrorImg } from "../../constant/ImagesS3";
import { ROUTES } from "../../constant/routes";
import { categoryOptions } from "../../constant/staticData";
import { filterServicesOptions, handleNameKeyDown } from "../../constant/utils";
import { useApiData } from "../../context/ApiDataContext";
import useCheckUserRegistration from "../../hooks/useCheckUserRegistration";
import { sendOTP } from "../../services/form.service";
import {
  ConfirmationCheckboxes,
  PhoneNumberInput,
  RadioButtonGroup,
  ReusableAutocomplete,
  TextInput,
} from "../common";
import LocationAutocomplete from "../common/LocationAutocomplete";
import AlertDialog from "../SignUpFormVerification/AlertDialog";
import CustomDialog from "../SignUpFormVerification/CustomDialog";
import OTPComponent from "../SignUpFormVerification/OTPComponent";
import { styles } from "./styleSheet";
const FormDetailsStep = React.memo(
  ({
    formik,
    activeStep,
    onNextStep,
    onPrevStep,
    errorMessage,
    handleErrorFalse,
    error,
    successMessage,
  }) => {
    /* The `useApiData` custom hook to get the `serviceList` data from the API. */
    const { serviceList } = useApiData();
    /* The `useCheckUserRegistration` custom hook to check if a user is
    registered based on the provided mobile number and active step. It is extracting the `isRegistered`, `loading`, and `checkPhoneError`. */
    const { isRegistered, loading, checkPhoneError } = useCheckUserRegistration(
      formik?.values?.mobileNumber,
      activeStep
    );
    const { executeRecaptcha } = useGoogleReCaptcha();
    const isSmallScreen = useMediaQuery("(max-width: 600px)");
    const [openOtpPopUp, setOpenOtpPopUp] = useState(false);
    const [currentLocationError, setCurrentLocationError] = useState(false);
    const [homeLocationError, setHomeLocationError] = useState(false);
    const [eighteenOrOlder, setEighteenOrOlder] = useState(false);
    const [otpInitLoading, setOtpInitLoading] = useState(false);
    const [sendOtpLimitError, setSendOtpLimitError] = useState(false);
    const [sendOtpErrorMessage, setSendOtpErrorMessage] = useState("");
    const [userAlreadyExistDialog, setUserAlreadyExistDialog] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const handleBusinessTypeChange = (e) => {
      const value = e.target.value;
      formik?.setFieldValue("businessType", value);
    };

    useEffect(() => {
      if (isRegistered) {
        const timer = setTimeout(() => {
          setShowLoader(true);
          const contentTimer = setTimeout(() => {
            setShowLoader(false);
            setUserAlreadyExistDialog(true);
          }, 2000);

          return () => clearTimeout(contentTimer);
        }, 1000);

        return () => clearTimeout(timer);
      }
    }, [isRegistered]);

    useEffect(() => {
      if (successMessage) {
        setOpenOtpPopUp(false);
      }
    }, [successMessage]);

    const handleExperienceChange = (e) => {
      const value = e.target.value;
      if (
        value === "" ||
        value === "0" ||
        (/^[1-9]\d*$/.test(value) && !/^0\d+$/.test(value))
      ) {
        formik?.setFieldValue(e.target.name, value);
      }
    };

    const handleEighteenOrOlderChange = (event) => {
      formik?.setFieldValue("isEighteenOrOlder", event.target.checked);
    };

    const handleNextStep = async () => {
      await formik?.validateForm();
      formik?.setTouched({
        mobileNumber: true,
        name: true,
        businessName: true,
        experience: true,
        serviceId: true,
        homeLocationObject: true,
        serviceOrCurrentLocationObject: true,
      });

      const hasErrorsForUser = ["mobileNumber", "name"]?.some(
        (key) => formik?.errors[key]
      );

      const hasErrorsForProvider = [
        "serviceId",
        "mobileNumber",
        "businessType",
        "businessName",
        "name",
        "experience",
      ]?.some((key) => formik?.errors[key]);

      if (
        formik?.values?.userType === "User"
          ? !hasErrorsForUser && !isRegistered && !loading && !checkPhoneError
          : !hasErrorsForProvider &&
            !isRegistered &&
            !loading &&
            !checkPhoneError
      ) {
        onNextStep();
      }
    };

    /* The function `handleFormSubmit` validates a form, checks for errors, generates a Google reCAPTCHA token, and sends an OTP for mobile number verification.*/
    const handleFormSubmit = async () => {
      if (!executeRecaptcha) {
        return;
      }

      // Validate form
      await formik.validateForm();

      // Check if form is valid for submission
      if (isDisableForSubmit(formik)) {
        if (isEmpty(formik?.values?.serviceOrCurrentLocationObject)) {
          setCurrentLocationError(true);
        }
        if (isEmpty(formik?.values?.homeLocationObject)) {
          setHomeLocationError(true);
        }
        if (formik?.values?.userType === "Service Provider") {
          setEighteenOrOlder(true);
        }
        return;
      }

      try {
        // Execute reCAPTCHA
        setOtpInitLoading(true);
        const googleReCaptchToken = await executeRecaptcha();

        // Prepare payload
        const formattedMobileNumber = "+91" + formik?.values?.mobileNumber;
        const payload = {
          captchaToken: googleReCaptchToken,
          mobileNumber: formattedMobileNumber,
        };
        // Send OTP
        await sendOTP(payload);
        // Open OTP popup
        setOpenOtpPopUp(true);
      } catch (error) {
        setOtpInitLoading(false);

        console.error("Error resending OTP:", error?.response?.data?.message);
        setSendOtpErrorMessage(error?.response?.data?.message);
        setSendOtpLimitError(true);
      } finally {
        setOtpInitLoading(false);
      }
    };

    return (
      <>
        {showLoader && (
          <div className="loader-overlay">
            <img
              src={BataiyoLoader}
              alt="bataiyo-loader"
              className="bataiyo-loader-gif"
            />
          </div>
        )}
        {activeStep === 1 && (
          <>
            {formik?.values?.userType === "Service Provider" && (
              <RadioButtonGroup
                label="Select Type *"
                name="businessType"
                options={categoryOptions}
                value={formik.values.businessType}
                onChange={handleBusinessTypeChange}
              />
            )}
            <PhoneNumberInput
              name="mobileNumber"
              type="tel"
              label="Phone Number *"
              placeholder="Enter Phone Number"
              value={formik.values.mobileNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.mobileNumber &&
                Boolean(formik.errors.mobileNumber)
              }
              helperText={
                formik.touched.mobileNumber && formik.errors.mobileNumber
              }
              isRegistered={isRegistered}
              checkPhoneError={checkPhoneError}
              loading={loading}
            />
            {formik?.values?.userType === "Service Provider" && (
              <ReusableAutocomplete
                isServices={true}
                options={serviceList}
                getOptionLabel={(option) => option.serviceName || ""}
                value={serviceList?.find(
                  (option) => option?._id === formik.values.serviceId
                )}
                onChange={(e, newValue) => {
                  formik.setFieldValue("serviceId", newValue?._id || "");
                }}
                onBlur={() => {
                  formik.setFieldTouched("serviceId", true);
                }}
                formLabel={"Select Service *"}
                placeholder={"Select Service"}
                error={
                  formik.touched.serviceId && Boolean(formik.errors.serviceId)
                }
                helperText={formik.touched.serviceId && formik.errors.serviceId}
                isOptionEqualToValue={(option, value) =>
                  option?._id === value?._id
                }
                filterOptions={(options, state) =>
                  filterServicesOptions(options, state)
                }
                onKeyDown={handleNameKeyDown}
              />
            )}
            {formik?.values?.userType === "Service Provider" &&
              formik.values.businessType === "Business" && (
                <TextInput
                  name="businessName"
                  label="Business Name *"
                  placeholder="Business Name *"
                  type="text"
                  value={formik.values.businessName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.businessName &&
                    Boolean(formik.errors.businessName)
                  }
                  helperText={
                    formik.touched.businessName && formik.errors.businessName
                  }
                  onKeyDown={handleNameKeyDown}
                />
              )}
            <TextInput
              name="name"
              label="Full Name *"
              placeholder="Enter your Full Name"
              type="text"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              onKeyDown={handleNameKeyDown}
            />
            {formik?.values?.userType === "Service Provider" && (
              <TextInput
                name="experience"
                label="Years of Experience *"
                placeholder="Experience"
                value={formik.values.experience}
                onChange={handleExperienceChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.experience && Boolean(formik.errors.experience)
                }
                helperText={
                  formik.touched.experience && formik.errors.experience
                }
                pattern="[0-9]*"
                inputmode="numeric"
                type="tel"
                maxLength={
                  formik?.values?.businessType === "Individual" ? 3 : 4
                }
              />
            )}
          </>
        )}
        {activeStep === 2 && (
          <>
            {formik?.values?.userType === "Service Provider" && (
              <div style={styles.signupDescBox}>
                <p style={styles.signupDescText}>
                  Please tick the boxes and give your consent for age, terms and
                  conditions.
                </p>
              </div>
            )}
            <LocationAutocomplete
              value={formik.values.serviceOrCurrentLocationObject}
              onChange={(event, newValue) => {
                formik.setFieldValue(
                  "serviceOrCurrentLocationObject",
                  newValue
                );
                setCurrentLocationError(false);
                formik.setFieldError("serviceOrCurrentLocationObject", "");
              }}
              formLabel={
                formik?.values?.userType === "Service Provider"
                  ? "Service Location *"
                  : "Current Location *"
              }
              placeholder={
                formik?.values?.userType === "Service Provider"
                  ? "Enter your service location"
                  : "Enter your current location"
              }
              onBlur={(e) => {
                formik.setFieldTouched("serviceOrCurrentLocationObject", true);
                formik.validateField("serviceOrCurrentLocationObject");
              }}
              error={
                formik.touched.serviceOrCurrentLocationObject &&
                Boolean(formik.errors.serviceOrCurrentLocationObject)
              }
              helperText={
                formik.touched.serviceOrCurrentLocationObject &&
                formik.errors.serviceOrCurrentLocationObject
              }
              locationError={currentLocationError}
              isServices={formik?.values?.userType === "Service Provider"}
              isOnlyUser={formik?.values?.userType === "User"}
            />
            <LocationAutocomplete
              value={formik.values.homeLocationObject}
              onChange={(event, newValue) => {
                formik.setFieldValue("homeLocationObject", newValue);
                setHomeLocationError(false);
                formik.setFieldError("homeLocationObject", "");
              }}
              formLabel={"Home Location *"}
              placeholder={"Enter your home location"}
              onBlur={(e) => {
                formik.setFieldTouched("homeLocationObject", true);
                formik.validateField("homeLocationObject");
              }}
              error={
                formik.touched.homeLocationObject &&
                Boolean(formik.errors.homeLocationObject)
              }
              helperText={
                formik.touched.homeLocationObject &&
                formik.errors.homeLocationObject
              }
              locationError={homeLocationError}
              isHomeLocation={true}
            />
            {formik?.values?.userType === "Service Provider" && (
              <ConfirmationCheckboxes
                isEighteenOrOlder={formik.values.isEighteenOrOlder}
                // isAutoApproved={formik.values.isAutoApproved}
                handleEighteenOrOlderChange={handleEighteenOrOlderChange}
                // handleAutoApprovalChange={handleAutoApprovalChange}
                error={formik.errors.isEighteenOrOlder}
                helperText={formik.errors.isEighteenOrOlder}
                eighteenOrOlder={eighteenOrOlder}
              />
            )}
            <div style={styles.privacyAndTermsText}>
              By logging in you're accepting bataiyo's
              <br />
              <Link
                to={ROUTES.TERMS}
                target="_blank"
                className="form-tnc-privacy-link "
              >
                Terms of Use
              </Link>{" "}
              and{" "}
              <Link
                to={ROUTES.PRIVACY_POLICY}
                target="_blank"
                className="form-tnc-privacy-link "
              >
                Privacy Policy
              </Link>
            </div>
          </>
        )}
        <div style={styles.stepsBottonBox}>
          <Button sx={styles.backButton(isSmallScreen)} onClick={onPrevStep}>
            Back
          </Button>
          {activeStep === 2 ? (
            <Button
              type="button"
              sx={styles.stepSubmitButton(isSmallScreen)}
              disabled={otpInitLoading}
              onClick={handleFormSubmit}
            >
              {otpInitLoading ? "Sending OTP..." : "Submit"}
            </Button>
          ) : (
            <Button
              type="button"
              sx={styles.stepSubmitButton(isSmallScreen)}
              onClick={handleNextStep}
            >
              Next
            </Button>
          )}
        </div>
        {openOtpPopUp && (
          <OTPComponent
            isOpen={openOtpPopUp}
            isClose={() => setOpenOtpPopUp(false)}
            signUpFormik={formik}
            errorMessage={errorMessage}
            handleErrorFalse={handleErrorFalse}
            error={error}
          />
        )}
        {sendOtpLimitError && (
          <AlertDialog
            isDialogOpen={sendOtpLimitError}
            handleClose={() => setSendOtpLimitError()}
            message={sendOtpErrorMessage}
            image={ErrorImg}
          />
        )}
        {userAlreadyExistDialog && (
          <CustomDialog
            isDialogOpen={userAlreadyExistDialog}
            handleClose={() => setUserAlreadyExistDialog(false)}
          />
        )}
      </>
    );
  }
);

FormDetailsStep.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      userType: PropTypes.string,
      businessType: PropTypes.string,
      mobileNumber: PropTypes.string,
      serviceId: PropTypes.string,
      businessName: PropTypes.string,
      name: PropTypes.string,
      experience: PropTypes.string,
      serviceOrCurrentLocationObject: PropTypes.object,
      homeLocationObject: PropTypes.object,
      isEighteenOrOlder: PropTypes.bool,
      isAutoApproved: PropTypes.bool,
    }).isRequired,
    touched: PropTypes.object,
    errors: PropTypes.object,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func,
    validateForm: PropTypes.func,
    validateField: PropTypes.func,
    setFieldError: PropTypes.func,
    setErrors: PropTypes.func,
    setTouched: PropTypes.func,
  }).isRequired,
  onNextStep: PropTypes.func,
  onPrevStep: PropTypes.func,
  activeStep: PropTypes.number,
  errorMessage: PropTypes.string,
  handleErrorFalse: PropTypes.func,
  error: PropTypes.bool,
  successMessage: PropTypes.bool,
};

export default memo(FormDetailsStep);
