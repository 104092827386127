import PropTypes from "prop-types";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { ERROR_MESSAGES } from "../constant/messages";
import { getServiceList } from "../services/form.service";
import { capitalizeWords } from "../constant/utils";

const ApiDataContext = createContext();

export const ApiDataProvider = ({ children }) => {
  const [serviceList, setServiceList] = useState([]);
  const [error, setError] = useState(null);

  /* The `useEffect` hook in the provided code snippet is responsible for fetching service data from an API when the component mounts.*/
  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await getServiceList();
        const serviceData = response?.data?.result || [];
        const filteredServiceData = serviceData
          ?.filter((service) => !service?.comingSoon)
          ?.map((service) => ({
            ...service,
            serviceName: capitalizeWords(service?.serviceName),
          }));
        setServiceList(filteredServiceData);
      } catch (error) {
        console.error(ERROR_MESSAGES.fetchDataError, error);
        setError(error);
      }
    };

    fetchServices();
  }, []);

  /* The `useMemo` hook is used to memoize the context value object that will be provided to the `ApiDataContext.Provider`.*/
  const contextValue = useMemo(
    () => ({
      serviceList,
      error,
    }),
    [serviceList, error]
  );

  return (
    <ApiDataContext.Provider value={contextValue}>
      {children}
    </ApiDataContext.Provider>
  );
};

ApiDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useApiData = () => useContext(ApiDataContext);
