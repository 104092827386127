import { Box, Grid, Paper, useMediaQuery } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { BataiyoLoader } from "../../constant/ImagesS3";
import { ERROR_MESSAGES } from "../../constant/messages";
import { servicesStaticData } from "../../constant/servicesStaticData";
import { capitalizeWords, splitServiceNames } from "../../constant/utils";
import { styles } from "./styleSheet";

function AllServices() {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const isMediumScreen = useMediaQuery("(max-width: 1024px)");
  const [categories, setCategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("all");
  const [loading, setLoading] = useState(false);

  const fetchCategoriesAndServices = useCallback(async () => {
    const filterServices = (services) =>
      services?.filter((service) => !service?.comingSoon);

    const mapServices = (services, categoryId, subcategoryId) =>
      filterServices(services)?.map((service) => ({
        ...service,
        categoryId,
        subcategoryId,
      }));

    const mapSubcategories = (subcategories, categoryId) =>
      subcategories
        ?.filter(
          (subcategory) => filterServices(subcategory?.services)?.length > 0
        )
        ?.map((subcategory) => ({
          subcategoryId: subcategory?.subcategoryId,
          subcategoryName: subcategory?.subcategoryName,
          subcategoryImage: subcategory?.subcategoryImage,
          services: mapServices(
            subcategory?.services,
            categoryId,
            subcategory?.subcategoryId
          ),
        }));

    const mapCategories = (categories) =>
      categories?.map((category) => ({
        categoryId: category?.categoryId,
        categoryName: category?.categoryName,
        categoryImage: category?.categoryImage,
        subcategories: mapSubcategories(
          category?.subcategories,
          category?.categoryId
        ),
      }));

    setLoading(true);
    try {
      const fetchedCategories = mapCategories(servicesStaticData);
      setCategories(fetchedCategories);
    } catch (error) {
      console.error(ERROR_MESSAGES.fetchDataError, error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCategoriesAndServices();
  }, [fetchCategoriesAndServices]);

  const handleCategoryChange = (id) => {
    setSelectedCategoryId(id);
  };

  const selectedCategory = categories?.find(
    (category) => category?.categoryId === selectedCategoryId
  );

  const displayedCategories =
    selectedCategoryId === "all" ? categories : [selectedCategory];

  return (
    <div style={styles.allServicesContainer(isMediumScreen)}>
      <div>
        <div style={styles.allServicesTextBox(isSmallScreen, isMediumScreen)}>
          <h3 style={styles.popularServicesText(isSmallScreen)}>
            All Services
          </h3>
          <div style={styles.categoriesMainContainer(isMediumScreen)}>
            <div style={styles.categoriesTextStyle(isSmallScreen)}>
              Categories
            </div>
            <div style={styles.categoriesContainer}>
              <Box
                sx={styles.categoryBox(
                  selectedCategoryId === "all",
                  isSmallScreen
                )}
                onClick={() => handleCategoryChange("all")}
              >
                All Services
              </Box>

              {/* Other categories */}
              {categories?.map((category) => (
                <Box
                  key={category?.categoryId}
                  sx={styles.categoryBox(
                    selectedCategoryId === category?.categoryId,
                    isSmallScreen
                  )}
                  onClick={() => handleCategoryChange(category?.categoryId)}
                >
                  {capitalizeWords(category?.categoryName)}
                </Box>
              ))}
            </div>
          </div>
        </div>
        <div style={styles.horizontalLine} />
      </div>
      {loading ? (
        <div className="loader-overlay">
          <img
            src={BataiyoLoader}
            alt="bataiyo-loader"
            className="bataiyo-loader-gif"
          />
        </div>
      ) : (
        <div>
          {displayedCategories?.map((category) => (
            <div
              key={category?.categoryId}
              style={styles.displayCategoriesCont}
            >
              <div style={styles.categoriesNameText(isSmallScreen)}>
                <div style={styles.categoryImgBox}>
                  <img
                    src={category?.categoryImage}
                    alt={category?.categoryName}
                    style={styles.categoryIcon}
                  />
                  <span>{capitalizeWords(category?.categoryName)}</span>
                </div>
              </div>
              <Grid container spacing={2}>
                {category.subcategories?.map((subcategory) => {
                  // Determine if subcategory name should be hidden
                  const hideSubcategoryName =
                    subcategory.subcategoryName.toLowerCase() ===
                      category.categoryName.toLowerCase() ||
                    category.subcategories.length === 1;

                  return (
                    <React.Fragment
                      key={`${subcategory?.subcategoryId}-${subcategory?.subcategoryName}`}
                    >
                      {subcategory.services?.length > 0 &&
                        !hideSubcategoryName && (
                          <Grid item xs={12}>
                            <div style={styles.subcategoryImgBox}>
                              <img
                                src={subcategory?.subcategoryImage}
                                alt={subcategory?.subcategoryName}
                                style={styles.subcategoryIcon}
                              />

                              <h4
                                style={styles.subCategoriesText(isSmallScreen)}
                              >
                                {capitalizeWords(subcategory?.subcategoryName)}
                              </h4>
                            </div>
                          </Grid>
                        )}
                      {subcategory.services?.map((service) => (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          lg={3}
                          key={service?.serviceId}
                        >
                          <Paper
                            elevation={3}
                            sx={styles.servicesCardPaper(isSmallScreen)}
                          >
                            <div style={styles.serviceNameBox}>
                              <img
                                src={service?.serviceIcon}
                                alt={service?.serviceName}
                                style={styles.servicesIcon(isSmallScreen)}
                              />
                              <div
                                style={styles.serviceNameText(isSmallScreen)}
                              >
                                {splitServiceNames(service?.serviceName)}
                              </div>
                              {/* <div style={styles.arrowIcon(isSmallScreen)}>
                  <ArrowOutwardIcon />
                </div> */}
                            </div>
                          </Paper>
                        </Grid>
                      ))}
                    </React.Fragment>
                  );
                })}
              </Grid>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default AllServices;
