import {
  Box,
  Card,
  CardContent,
  CardMedia,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect } from "react";
import { splitServiceNames } from "../../constant/utils";
import { useApiData } from "../../context/ApiDataContext";
import { styles } from "./styleSheet";

function PopularServices() {
  const { serviceList } = useApiData();
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const isMediumScreen = useMediaQuery("(max-width: 1024px)");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Filter services based on popularServiceSortOrder
  const filteredServices = serviceList
    ?.filter((service) => service.popular)
    .sort((a, b) => a.popularServiceSortOrder - b.popularServiceSortOrder);
  return (
    <div style={styles.servicesMainContainer(isMediumScreen)}>
      <h1 style={styles.servicesHeading(isSmallScreen)}>Trusted Services</h1>
      {/* Popular Services Section */}
      <div style={styles.popularServicesContainer}>
        <div style={styles.popularServicesHeadingBox(isSmallScreen)}>
          <h3 style={styles.popularServicesText(isSmallScreen)}>
            Popular Services
          </h3>
        </div>
        {/* Container for horizontal scroll */}
        <Box sx={styles.horizonatalContainer}>
          {filteredServices?.map((service) => (
            <Box key={service?._id} sx={styles.serviceCardItem(isSmallScreen)}>
              <Card sx={styles.popularServicesCard(isSmallScreen)}>
                <CardMedia
                  component="img"
                  alt={service?.serviceName}
                  sx={styles.popularServicesImg(isSmallScreen)}
                  image={service?.serviceImage}
                />
                <CardContent sx={styles.cardContentBox(isSmallScreen)}>
                  <Box sx={styles.serviceName(isSmallScreen)}>
                    {splitServiceNames(service?.serviceName)}
                  </Box>
                  <Box sx={styles.serviceDesc(isSmallScreen)}>
                    {service?.description}
                  </Box>
                </CardContent>
              </Card>
            </Box>
          ))}
        </Box>
      </div>
    </div>
  );
}

export default PopularServices;
