import { useMediaQuery } from "@mui/material";
import React, { memo } from "react";
import { HeadingLeftImg, HeadingMiddleImg } from "../../constant/ImagesS3";
import { heroSectionData } from "../../constant/staticData";
import { useDialog } from "../../context/DialogContext";
import HeroSectionAnimation from "../../LottieAnimation/HeroSectionAnimation";
import RegistrationMarquee from "../HeroSection/RegistrationMarquee";
import LogosSection from "../LogosSection/LogosSection";
import "./HeroSection.css";
const HeroSection = () => {
  const { handleDialogOpen } = useDialog();
  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  return (
    <div className="hero-container" id="hero">
      <div>
        <RegistrationMarquee />
      </div>
      <section className="hero-section">
        <div className="hero-content">
          <img
            className="hero-heading-top-img"
            src={HeadingLeftImg}
            alt="bataiyo-heading"
            loading="lazy"
          />
          <h1 className="hero-heading">{heroSectionData?.title}</h1>
          {heroSectionData?.description?.map((item, index) => (
            <p
              className={`hero-description ${
                index === heroSectionData?.description?.length - 1
                  ? "last-description"
                  : ""
              }`}
              key={index}
            >
              {isSmallScreen &&
              index === heroSectionData?.description?.length - 1 ? (
                <>
                  <b>- Bataiyo</b> |{" "}
                  <span className="last-description-span">
                    “<b>Bharosa Apno Ka</b>”
                  </span>
                </>
              ) : (
                item
              )}
            </p>
          ))}
          <div className="hero-button-section-box">
            <button className="hero-register-button" onClick={handleDialogOpen}>
              REGISTER NOW
            </button>
            <button className="hero-comming-soon-button" disabled>
              APP COMING SOON
            </button>
          </div>
        </div>
        <img
          className="hero-heading-middle-img"
          src={HeadingMiddleImg}
          alt="bataiyo-heading"
          loading="lazy"
        />
        <HeroSectionAnimation />
      </section>
      <LogosSection />
    </div>
  );
};

export default memo(HeroSection);
