import { useMediaQuery } from "@mui/material";
import React from "react";
import Marquee from "react-fast-marquee";
import { valuesList } from "../../constant/staticData";
import "./LogosSection.css";

function LogosSection() {
  const isSmallScreen = useMediaQuery("(max-width: 950px)");

  return (
    <section className="logo-section-container">
      {isSmallScreen && <div className="core-values-heading">Our Values</div>}
      {isSmallScreen ? (
        <Marquee direction="left" speed={50}>
          {valuesList?.slice(1).map((item) => (
            <div className="logo-section-box" key={item?.id}>
              {item?.img && (
                <img src={item?.img} alt="bg-logo" loading="lazy" />
              )}
              <p className="values-name">{item?.name}</p>
            </div>
          ))}
        </Marquee>
      ) : (
        <>
          {valuesList?.map((item) => (
            <div className="logo-section-box" key={item?.id}>
              {item?.img && (
                <img src={item?.img} alt="bg-logo" loading="lazy" />
              )}
              <p className="values-name">{item?.name}</p>
            </div>
          ))}
        </>
      )}
    </section>
  );
}

export default LogosSection;
